import React from 'react'
import { graphql } from 'gatsby'

// Components
import {
  TitleAlt,
  TitleDefault,
  BannerDefault,
  ContentDefault,
} from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import ServicesComp from 'components/Services'

// CSS
import 'styles/templates/ServicesTemplate.scss'

export const pageQuery = graphql`
  query ($wordpress_id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        backgroundImage: background_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }

        bannerTitle: banner_title
        bannerSubtitle: banner_subtitle
        bannerText: banner_text
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

function Services({
  data: {
    page: { path, acf, yoast_meta },
  },
}) {
  return (
    <Layout backgroundImage={acf.backgroundImage}>
      {acf.backgroundImage && acf.backgroundImage.localFile ? (
        <SEO
          yoast={{ meta: yoast_meta }}
          pathname={path}
          image={acf.backgroundImage.localFile.childImageSharp.fixed.src}
          article
        />
      ) : (
        <SEO yoast={{ meta: yoast_meta }} pathname={path} article />
      )}
      <div className="servicess-template">
        <BannerDefault className="py-5">
          <div className="row">
            <div className="col-12 col-lg-6 py-4">
              <TitleAlt>{acf.bannerTitle}</TitleAlt>
              <TitleDefault asH1 className="pl-lg-5">
                {acf.bannerSubtitle}
              </TitleDefault>
              <div className="pr-lg-5 mr-lg-5">
                <ContentDefault className="mt-4">
                  <div dangerouslySetInnerHTML={{ __html: acf.bannerText }} />
                </ContentDefault>
              </div>
            </div>
            <div className="col-12 col-lg-6" />
          </div>
        </BannerDefault>

        <div className="pb-5 container">
          <div className="pb-5">
            <ServicesComp />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Services
